import { EditorType } from '@wix/platform-editor-sdk';

const cache: any = {};

export const createGallery = async ({
  compId,
  originCompId,
  originInstanceId,
  instance,
  refresh,
  editorType,
  editorSubType,
  httpClient,
  editorSDK,
  t,
}: any) => {
  if (!compId || cache[compId]) {
    return;
  } else {
    cache[compId] = true;
  }
  const editorName = convertEditorType(editorType, editorSubType);
  try {
    const requestUrl =
      'https://editor.wix.com/_api/pro-gallery-editor-webapp/v1/app/galleries'; // This was tested with editor. not editorx. might have CORS when we start testing it to

    const response = await httpClient.post(
      requestUrl,
      JSON.stringify({
        compId,
        originCompId,
        originInstanceId,
        editorType: editorName,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance,
        },
      },
    );
    const data = response.data;
    if (typeof data?.galleryId === 'string') {
      refresh('', {
        shouldFetchData: true,
        source: 'AFTER_GALLERY_CREATED',
      });
      return Promise.resolve(data?.galleryId);
    } else {
      throw new Error('no galleryId returned from the server');
    }
  } catch (e) {
    const componentRef = await editorSDK.document.components.getById('', {
      id: compId,
    });
    await editorSDK.components.remove('', { componentRef });
    editorSDK.editor.showNotification('', {
      title: t('app.create.errorMessage.title'),
      message: t('app.create.errorMessage.description'),
      type: 'error',
      link: { caption: 'Restore' },
    });
    console.error(
      'Could not create a new gallery in the server using the provided data',
      JSON.stringify({
        compId,
        originCompId,
        originInstanceId,
        editorType: editorName,
      }),
      e,
    );
  }
};

export const convertEditorType = (type: any, editorSubType: any) => {
  switch (type) {
    case EditorType.Classic:
      return 'EDITOR';
    case EditorType.Responsive:
      if (editorSubType === 'STUDIO') {
        return 'STUDIO';
      }
      return 'EDITORX';
    case EditorType.ADI:
      return 'ADI';
    default:
      // there are two more types in the possible EditorTypes, our server doesnt know about them
      return type;
  }
};
